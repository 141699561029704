<template>
  <!-- Gallery header -->
  <div
    ref="galleryRef"
    class="poi-top-gallery -mx-4 mb-4"
  >
    <carousel
      v-if="gallery.length > 0"
      :items-to-show="1"
      wrap-around
      :autoplay="autoplayTime"
      :mouse-drag="gallery.length > 1"
      :touch-drag="gallery.length > 1"
    >
      <slide
        v-for="(image, i) in gallery"
        :key="i"
      >
        <div
          :style="`background-image: url(${image.attributes.urls.md});`"
          class="bg-center bg-cover w-full h-60 relative"
          @click="handleImageModalDisplay(image)"
        >
          <div class="rounded-full bg-theme-900 text-white py-1 px-2 font-bold text-sm w-fit text-right absolute bottom-10 right-4">
            {{ t('ttmt.common.counter', { dividend: i + 1, divisor: gallery.length }) }}
          </div>
        </div>
      </slide>
    </carousel>

    <div
      v-else
      class="bg-center bg-contain bg-no-repeat w-full h-60"
      style="background-image: url('/images/shared/placeholders/pointsofinterest-landscape.png');"
    />
  </div>

  <!-- Modal displayed when clicking on image -->
  <image-modal
    :resource="resourceImage"
    :show="imageModalDisplayed"
    @closed="handleImageModalCancel"
  />

  <!-- Main information -->
  <app-card-item
    v-show="showAdvice"
    class="p-3 relative -top-10 -mb-8"
  >
    <div
      class="flex items-center justify-between"
    >
      <span class="font-bold text-lg mr-1">
        {{ l10nTitle }}
      </span>

      <!-- Certification -->
      <span
        v-if="resource.attributes.certified"
      >
        <app-icon
          library="coolicon"
          size="text-2xl"
          name="circle_check_outline"
        />
      </span>
    </div>

    <div class="text-gray-500 text-xs">
      <div
        class="flex items-center"
      >
        <!-- Category -->
        <span
          v-if="getAttributeTranslation(resource.relationships.category?.attributes?.label)"
          class="-ml-1"
        >
          <app-icon
            library="coolicon"
            name="tag-outline"
            size="text-base"
          />
          {{ getAttributeTranslation(resource.relationships.category.attributes.label) }}
        </span>

        <!-- Stars -->
        <template
          v-if="resource.attributes.stars > 0"
        >
          <span class="mx-1">
            &#183;
          </span>

          <span
            v-for="i in resource.attributes.stars"
            :key="i"
          >
            <app-icon
              library="fontawesome"
              name="star"
            />
          </span>
        </template>
      </div>

      <!-- Distance -->
      <div v-if="distanceToDisplay">
        <app-icon
          library="local"
          name="walk"
          size="w-2.5"
          :classes="['mr-1']"
        />
        {{ distanceToDisplay }}
      </div>

      <!-- Tags -->
      <div
        v-if="props.resource.relationships.tags"
        class="flex flex-wrap"
      >
        <div
          v-for="(tag, i) in props.resource.relationships.tags"
          :key="i"
          class="bg-gray-500 text-white py-0.5 px-2 w-fit rounded-full mr-1 last:mr-0 my-0.5"
        >
          {{ getAttributeTranslation(tag.attributes.label) }}
        </div>
      </div>

      <!-- Approximate address -->
      <div
        v-if="approximateLocation(props.resource.relationships.address)"
        class="flex items-center"
      >
        <app-icon
          library="coolicon"
          name="location_outline"
          class="-ml-1.5 mr-0.5"
        />

        <span>
          {{ approximateLocation(props.resource.relationships.address) }}
        </span>
      </div>

      <div class="flex justify-between items-baseline">
        <!-- Website -->
        <a
          v-if="resource.attributes.contact.website"
          :href="resource.attributes.contact.website"
          target="_blank"
          class="app-link block mt-3"
        >
          {{ t('ttmt.common.actions.see_website') }}
        </a>

        <!-- Opening Hours -->
        <div class="app-link mt-3">
          <div
            v-if="resource?.attributes?.opening_hours.length > 0"
          >
            <app-button
              font-size="xs"
              :label="t('ttmt.points_of_interest.pages.show.opening_hours.show')"
              feature="show"
              @click="handleOpeningHoursModal"
            />

            <!-- Modal -->
            <app-overlay
              :show="openingHoursModalDisplayed"
              @clicked="handleOpeningHoursCancelModal"
            >
              <app-modal
                text-align-classes="text-left"
                @closed="handleOpeningHoursCancelModal"
              >
                <point-of-interest-opening-hours
                  :is-expired="isExpired"
                  :opening-hours="resource.attributes.opening_hours"
                  :locale="selectedLocale"
                />
              </app-modal>
            </app-overlay>
          </div>
        </div>
      </div>
    </div>
  </app-card-item>

  <!-- Advice -->
  <app-alert
    v-show="showAdvice"
    design="info"
    :title="t('ttmt.advice.point_of_interest.card_title')"
    class="rounded-md text-sm font-bold mb-2"
  >
    <p class="text-sm font-normal">
      {{ getAttributeTranslation(resource?.relationships?.advice?.attributes?.content) }}
    </p>
  </app-alert>

  <!-- Denial reason -->
  <app-alert
    v-if="isDenied"
    design="danger"
    :title="t('ttmt.points_of_interest.denied.for_the_reason')"
  >
    <span class="italic block mb-2">
      "{{ resource.attributes.denial_reason }}"
    </span>

    {{ t('ttmt.points_of_interest.denied.moderation_again') }}
  </app-alert>

  <p
    v-if="resource.attributes?.fallback_locale"
    class="mb-2 text-xs font-semibold"
  >
    {{
      getAttributeTranslation(
        resource.attributes.title,
        resource.attributes.fallback_locale
      )
    }}
  </p>

  <p
    v-if="l10nDescription"
    class="mb-5 text-xs"
  >
    {{ l10nDescription }}
  </p>

  <!-- Video files  -->
  <div
    v-if="videos.length > 0"
    class="mb-5"
  >
    <app-video-player
      v-for="(video, i) in videos"
      :key="i"
      :src="video.attributes.url"
      class="my-4 max-w-screen-sm mx-auto"
    />
  </div>

  <!-- Contact -->
  <div class="mb-5 text-xs">
    <app-external-link
      v-if="resource.attributes.contact?.email"
      :link="resource.attributes.contact.email"
      type="email"
    />

    <app-external-link
      v-if="resource.attributes.contact?.phone"
      :link="resource.attributes.contact.phone"
      type="phone"
    />

    <app-external-link
      v-if="resource.attributes.contact?.website"
      :link="resource.attributes.contact.website"
    />

    <point-of-interest-address
      v-if="resource.relationships.address"
      :resource="resource"
    />
  </div>

  <!-- Embed Map -->
  <app-embed-map
    v-if="hasCoords || resource.relationships.address?.attributes.is_geocoding"
    :latitude="resource.relationships.address.attributes.latitude"
    :longitude="resource.relationships.address.attributes.longitude"
    :address="address"
    :is-geocoding="resource.relationships.address.attributes.is_geocoding"
    class="mb-3 max-w-screen-sm mx-auto"
  />

  <!-- Audio files  -->
  <div
    v-if="audios.length > 0 || resource.attributes.is_refreshing_audio"
    ref="audiosRef"
  >
    <div
      v-for="(audio, i) in audios"
      :key="i"
    >
      <!-- Hide audio if it's a TTS and the audio is waiting for refresh -->
      <app-card-item
        v-if="audio.attributes.source !== 'textToSpeech' || !resource.attributes.is_refreshing_audio"
        class="p-3 mb-5"
      >
        <div class="mx-auto text-center">
          <div class="mb-3">
            <p
              v-if="audio.attributes.name"
              class="font-bold mb-2"
            >
              {{ audio.attributes.name }}
            </p>

            <app-audio-player
              :src="audio.attributes.url"
              delay-playing
              class="max-w-full mx-auto"
            />

            <div class="mt-2">
              <p
                v-if="audio.attributes.locale"
                class="text-xs"
              >
                {{ t(`ttmt.locales.${audio.attributes.locale}`) }}
              </p>

              <app-card-item
                v-if="audio.attributes.source === 'textToSpeech'"
                class="text-xs w-fit px-3 py-1 font-bold mx-auto mt-1"
                background-classes="bg-gray-300"
              >
                {{ t('ttmt.media.audio.generated_audio') }}
              </app-card-item>
            </div>
          </div>
        </div>
      </app-card-item>
    </div>

    <!-- Refreshing audio placeholder -->
    <app-card-item
      v-if="resource.attributes.is_refreshing_audio"
      class="p-3 mb-5 flex items-center justify-center flex-col"
      background-classes="bg-gray-200 text-center"
    >
      <app-icon
        library="fontawesome"
        name="volume-up"
        size="2x"
        class="mb-2"
      />

      <p class="font-bold text-sm mb-1">
        {{ t('ttmt.points_of_interest.pages.show.is_refreshing_audio_1') }}
      </p>

      <p class="font-bold text-sm">
        {{ t('ttmt.points_of_interest.pages.show.is_refreshing_audio_2') }}
      </p>
    </app-card-item>

    <div class="text-center pb-6">
      <p
        v-if="hasDTRequiredFields"
        class="text-sm mb-1 mt-6"
      >
        {{
          t('ttmt.points_of_interest.pages.show.dt_required_datatourisme_fields',
            {
              import_source: resource.attributes.import_source,
              dt_created_by: resource.attributes.dt_created_by,
              dt_last_update: formatDateShort(resource.attributes.dt_last_update)
            }
          )
        }}
      </p>
    </div>
  </div>

  <!-- Actions -->
  <point-of-interest-actions
    :resource="resource"
    :show-labels="true"
    big-icons
    class="justify-center"
    @destroyed="destroyCallback"
  />

  <!-- Audios shortcut -->
  <teleport-wrapper to="#header-right">
    <app-button
      v-show="audios.length > 0 && !audiosVisible"
      :icon-props="{ library: 'fontawesome', name: 'volume-up' }"
      hide-label
      :hide-icon="false"
      rounded="rounded-1/2"
      width="9"
      height="9"
      padding="p-0"
      class="leading-none"
      @click="handleAudioShortcutClick"
    />
  </teleport-wrapper>
</template>

<script setup>
import {
  computed,
  ref,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Carousel, Slide } from 'vue3-carousel'

import useDatetime from '@shared/hooks/datetime'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import useGeoloc from '@shared/hooks/geoloc'
import useLocale from '@shared/hooks/locale'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppAudioPlayer from '@shared/components/ui/AppAudioPlayer.vue'
import AppEmbedMap from '@shared/components/ui/map/AppEmbedMap.vue'
import AppExternalLink from '@shared/components/ui/AppExternalLink.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppVideoPlayer from '@shared/components/ui/AppVideoPlayer.vue'
import ImageModal from '@app/components/resources/image/ImageModal.vue'
import PointOfInterestActions from '@app/components/resources/point_of_interest/PointOfInterestActions.vue'
import PointOfInterestAddress from '@shared/components/resources/point_of_interest/PointOfInterestAddress.vue'
import PointOfInterestOpeningHours from '@shared/components/resources/point_of_interest/PointOfInterestOpeningHours.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
})

const { t, locale } = useI18n()
const store = useStore()

const { formatDateShort } = useDatetime()
const { getAttributeTranslation } = useLocale()
const {
  gallery,
  audios,
  videos,
  isDenied,
  l10nTitle,
  l10nDescription,
  distanceToDisplay,
  hasCoords,
  hasDTRequiredFields,
  isExpired,
} = usePointOfInterest(props)

// ---------- GALLERY ----------

store.commit('header/SET_TRANSPARENT_BACKGROUND', true)

const galleryRef = ref() // template ref
window.addEventListener('scroll', handleGalleryScroll)

// Toggle header's transparent background when gallery is scrolled
function handleGalleryScroll() {
  const galleryHeight = galleryRef.value.offsetHeight
  const scrollLimit = window.scrollY + 64 // window's scroll height + header height

  if (
    !store.state.header.transparentBackground
    && galleryHeight > scrollLimit
  ) {
    store.commit('header/SET_TRANSPARENT_BACKGROUND', true)
  } else if (
    store.state.header.transparentBackground
    && galleryHeight <= scrollLimit
  ) {
    store.commit('header/SET_TRANSPARENT_BACKGROUND', false)
  }
}

// ---------- ACTIVE TRAVELLER FOLDER ----------

const activeFolderLoading = ref(false)

function fetchTravellerFolder() {
  activeFolderLoading.value = true

  store
    .dispatch('folders/getActiveFolder')
    .finally(() => {
      activeFolderLoading.value = false
    })
}

onBeforeMount(() => {
  fetchTravellerFolder()
})

// ---------- AUDIOS ----------

// Template ref
const audiosRef = ref()

// Scroll to audios section when the shortcut is clicked
function handleAudioShortcutClick() {
  window.scrollTo({ top: audiosRef.value.offsetTop, behavior: 'smooth' })
}

const audiosVisible = ref(false)
window.addEventListener('scroll', handleAudiosScroll)

// Display audio shortcut only when audios are not visible
function handleAudiosScroll() {
  const audiosPosition = audiosRef.value?.getBoundingClientRect()

  if (audiosPosition) {
    audiosVisible.value = (
      audiosPosition.top + 150 < window.innerHeight
        && audiosPosition.bottom >= 0
    )
  }
}

// ---------- ADDRESS ----------

const { approximateLocation } = useGeoloc()

const address = computed(() => (
  props.resource.relationships.address
))

// ---------- ADVICE ----------
/**
 * If the POI of activeFolder is equals to resource's id.
 * If traveller's folder is active AND owned by resource's POI.
 */
const showAdvice = computed(() => (
  (props.resource?.relationships?.advice !== null)
  && (props.resource?.relationships?.advice?.authorizations?.view === true)
))
// ---------- LIFECYCLE ----------

onBeforeUnmount(() => {
  store.commit('header/SET_TRANSPARENT_BACKGROUND', false)
  window.removeEventListener('scroll', handleAudiosScroll)
  window.removeEventListener('scroll', handleGalleryScroll)
})

// ---------- IMAGE MODAL ----------

const imageModalDisplayed = ref(false)
const resourceImage = ref()

function handleImageModalDisplay(image) {
  resourceImage.value = image
  imageModalDisplayed.value = true
}

function handleImageModalCancel() {
  imageModalDisplayed.value = false
}

// ---------- CAROUSEL ----------

const autoplayTime = computed(() => (
  gallery.length > 1 ? 5000 : null
))

// ---------- LOCALE ----------

const selectedLocale = ref(locale.value)

// ---------- OPENING HOURS ----------

const openingHoursModalDisplayed = ref(false)

function handleOpeningHoursCancelModal() {
  openingHoursModalDisplayed.value = false
}

function handleOpeningHoursModal() {
  openingHoursModalDisplayed.value = true
}
</script>
